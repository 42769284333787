.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:#e2082f;/* #012023;*/
  /* color: white; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.CarListContainer {
  margin-top: 100px; /* Space for the fixed header */
  padding: 20px;
  max-height: calc(100vh - 100px); /* Full height minus header height */
}
.App-link {
  color: #61dafb;
}
.App-header h1 {
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  font-family: 'Trebuchet MS', sans-serif; /* Rounded fonts */
  font-weight: bold; /* Make it bold */
  font-size: 1.5em;   /* Adjust size to your preference */
}

.App-header small {
  font-size: 0.8em; 
  margin-top: 0;    
}
.App-header .slogan {
  font-size: 0.6em; /* Adjust size */
  margin-top: 0;
  margin-bottom: 0.3em;
  font-weight: bold;
  font-style: italic; /* Optional: make it italic for emphasis */
  font-family: 'Trebuchet MS', sans-serif;
}
.App-header .logo {
  width: 8em; /* Adjust size */
  height: auto;
}
.App-header .contact {
  background-color: bisque;
  color: black;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
